import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { DateToolsService } from '../../../services/utils/date-tools.service';
import { DialogTimeDateSelectorParams } from '../../../interfaces/dialog-time-date-selector';

@Component({
  selector: 'app-dialog-time-date-selector',
  styleUrls: ['./dialog-time-date-selector.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-time-date-selector.component.html'
})

/**
 * Dialog component with date/time selectors. After close emitts value from dates selected.
 */
export class DialogTimeDateSelectorComponent {
  public currentDate: Date;
  public formGroup: UntypedFormGroup;

  /**
   * @description Contains intances from services or providers used in this component.
   * @param {DialogTimeDateSelectorParams} data - Injectable and expected data to use in this component.
   * @param {MatDialogRef<DialogTimeDateSelectorComponent>} dialogRef - Instance of this component to be used as dialog.
   * @param {DateToolsService} dateToolsService - Service with methods related to dates formats.
   * @param {UntypedFormBuilder} builder - Service to can use forms in this component.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogTimeDateSelectorParams,
    private dialogRef: MatDialogRef<DialogTimeDateSelectorComponent>,
    private dateToolsService: DateToolsService,
    private readonly builder: UntypedFormBuilder
  ) {
    this.currentDate = new Date();
    this.initForm();
  }

  /**
   * @description Method called when user changes date selected to apply differents validations.
   * @param {boolean} isFirstSelectorSection - Flag to set validation to dateTime pickers from first or second section inputs.
   */
  public onDateSelected(isFirstSelectorSection: boolean): void {
    if (!this.data.canEnteredDateBeGreaterThanCurrent) {
      this.validateDateTime();
    } else {
      if (isFirstSelectorSection) {
        this.formGroup.controls.firsDateSelector.setValidators(Validators.required);
        this.formGroup.controls.firsTimeSelector.setValidators(Validators.required);
        this.formGroup.controls.firsDateSelector.updateValueAndValidity();
        this.formGroup.controls.firsTimeSelector.updateValueAndValidity();
        this.formGroup.controls.firsDateSelector.markAsTouched();
        this.formGroup.controls.firsTimeSelector.markAsTouched();
      } else {
        this.formGroup.controls.secondaryDateSelector.setValidators(Validators.required);
        this.formGroup.controls.secondaryTimeSelector.setValidators(Validators.required);
        this.formGroup.controls.secondaryDateSelector.updateValueAndValidity();
        this.formGroup.controls.secondaryTimeSelector.updateValueAndValidity();
        this.formGroup.controls.secondaryDateSelector.markAsTouched();
        this.formGroup.controls.secondaryTimeSelector.markAsTouched();
      }
    }
  }

  /**
   * @description Method called when user clicks on "primary" button. This emmits value in form inputs.
   */
  public onSave(): void {
    const ordersSelected = this.formGroup.getRawValue();
    this.dialogRef.close(ordersSelected);
  }

  /**
   * @description Validates date/time selected by user in first date/time selectors versus current date
   * to avoid enter a greater date than current.
   */
  public validateDateTime(): void {
    const currentDate = new Date();
    const userDate = this.formGroup.controls.firsDateSelector.value;
    const userTime = this.formGroup.controls.firsTimeSelector.value;
    const fullDateTime = this.dateToolsService.setNewDateTime(userDate, userTime);

    if (fullDateTime > currentDate) {
      this.formGroup.controls.firsTimeSelector.setErrors({ greaterDateTimeThanCurrent: true });
    } else {
      this.formGroup.controls.firsTimeSelector.setErrors(null);
    }
  }

  /**
   * @description Initializes date inputs form.
   */
  private initForm(): void {
    const firstDateToPreload = this.data.firstDateToPreload ?? null;
    const secondaryDateToPreload = this.data.secondaryDateToPreload ?? null;
    this.formGroup = this.builder.group({
      firsDateSelector: new UntypedFormControl(this.data.shouldPreloadCurrentDate ? this.currentDate : firstDateToPreload),
      firsTimeSelector: new UntypedFormControl(this.data.shouldPreloadCurrentDate ? this.setTime(this.currentDate) :
        this.setTime(firstDateToPreload)),
      secondaryDateSelector: new UntypedFormControl(this.data.shouldPreloadCurrentDate ? this.currentDate : secondaryDateToPreload),
      secondaryTimeSelector: new UntypedFormControl(this.data.shouldPreloadCurrentDate ? this.setTime(this.currentDate) :
        this.setTime(secondaryDateToPreload)),
    });
  }

  /**
   * @description Receives a date to extract and format the time.
   * @param {Date} date - Date to process.
   * @returns {string} - Time from provided date as string.
   */
  private setTime(date: Date): string {
    if (date) {
      return this.dateToolsService.getTimeFromDate(date);
    }

    return null;
  }
}
