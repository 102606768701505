import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { QuickFilterProperties } from '../../interfaces/vehicle-access-control';

@Component({
  selector: 'app-quick-filters',
  templateUrl: './quick-filters.component.html',
  styleUrls: ['./quick-filters.component.scss']
})
export class QuickFiltersComponent implements OnInit {
  @Input() disabled?: boolean;
  @Output() filtered = new EventEmitter<string>();
  @Input() filters: Array<QuickFilterProperties>;
  @Input() preSelectedAction?: string;

  public selectedAction: string;

  /**
   * @description Angular lifecycle hook for component init.
   */
  public ngOnInit(): void {
    this.selectedAction = this.preSelectedAction ? this.preSelectedAction : undefined;
  }

  /**
   * @description Emits action to parent component.
   * @param {string} action Action identifier to emmit.
   */
  public emitAction(action: string): void {
    if (!this.disabled && this.selectedAction !== action) {
      this.selectedAction = action;
      this.filtered.emit(action);
    } else if (!this.disabled) {
      this.selectedAction = undefined;
      this.filtered.emit(undefined);
    }
  }
}
